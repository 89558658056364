import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import { useEvent } from '../utils/events';


export default function AlertDialog(props) {

    const [severity, setSeverity] = useState("info")
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("Title")
    const [description, setDescription] = useState("")
    const size = 24

    const color = {
        "info": "#0288D1",
        "warning": "#ED6C02",
        "error": "#D32F2F",
        "success": "#2E7D32"
    }

    const icon = {
        "info": <InfoIcon size={size}/>,
        "warning": <WarningIcon size={size}/>,
        "error": <ErrorIcon size={size}/>,
        "success": <SuccessIcon size={size}/>
    }

    /** receive the event for opening the dialog */
    const handleOpenDialog = (event) => {
        setTitle(event.detail.title)
        setSeverity(event.detail.severity)
        setDescription(event.detail.description)
        setOpen(true) 
    }
    
    /** Close the dialog */
    const handleClose = () => {
        setOpen(false);
    }

    useEvent("event:alert", handleOpenDialog)

    return (
    
        <Dialog open={open} onClose={handleClose} fullWidth sx={{maxWidth: '50%', margin:'10px auto'}}>
            <DialogTitle sx = {{ backgroundColor:color[severity] }} color="white">{icon[severity]}{title}!</DialogTitle>
            
            <DialogContent>
                <br></br>
                <Typography> 
                    {description||"Operation completed successfully."}
                </Typography> 

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color={severity}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}