import React, { useState } from "react";
import { Chip } from "@mui/material";


const ToggleChip = (props) => {

  const [selected, setSelected] = useState(false);

  const handleSelected = (event) => {
    setSelected(!selected);
    if (props.onClick)
        props.onClick(event, props.value, !selected);
  }

  return (
    <Chip
      label={props.label}
      onClick={handleSelected}
      color={selected ? "success" : "primary"}
      variant={selected ? "filled" : "outlined"}
      sx={{
        cursor: "pointer",
        padding: "5px",
        marginRight: "5px",
      }}
    />
  );
};

export default ToggleChip;
