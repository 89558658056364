

const __BLACKLIST__ = [
    //sql keywords
    "select",
    "from",
    "where",
    "group",
    "by",
    "order",
    "limit",
    "offset",
    "insert",
    "into",
    "values",
    "update",
    "delete",
    "drop",
    "create",
    "table",
    "alter",
    "add",
    "column",
    "index",
    "unique",
    "primary"
]


export const isTag = (word) => {

    //check if the word is in the blacklist
    if (word && __BLACKLIST__.includes(word.toLowerCase())) {
        return false
    }

    //check if the word is a valid alfanumeric string
    if (!word.match(/^[a-zA-Z0-9\s_()]+$/)) {
        return false
    }

    //check is at max 3 words
    if (word.split(" ").length > 3) {
        return false
    }

    return true
}