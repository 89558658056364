import "../../App.css";
import { LayerSwitcher, QgisContext, QgisProject, SaferMap } from "@SaferPlaces2023/safer-map";
import React, { useEffect, useState } from "react";
import {Box} from "@mui/material"
import {ReflexContainer, ReflexSplitter, ReflexElement} from 'react-reflex'
import 'react-reflex/styles.css'
import AppWrapper from "../widgets/AppWrapper";
import DigitalTwinCreatorRER from "../WizardComponents/DigitalTwinCreatorRER";
import DigitalTwinCreator from "../WizardComponents/DigitalTwinCreator";
import { LeftPanelWidth, RightPanelWidth } from "../themes";
import { ToolManager } from "../widgets/ToolManager";
import { defaultSettings } from "../dialog/settings/DefaultProjectSettings";
import { getUserName } from "../utils/const";
import { isRerUser, user_post } from "../utils/http";



export default function NewProjectPage() {
  
  const username = getUserName()

  const [project, setProject] = useState({
    qgis: {"@projectname":"", "@saveUser":username },
  });
  

  useEffect(() => {

    // Creo un nuovo progetto vuoto.
    user_post("/api/empty").then(response=>{
      const projectname = response.data.projectname
      sessionStorage.setItem("projectname", projectname)
      sessionStorage.setItem("linkname", projectname)
      setProject({
        url: `/projects/${username}/${projectname}/project.qgs`,
        qgis: {"@projectname":projectname, "@saveUser":username },
      })
    })
    
  },[]) //eslint-disable-line

  useEffect(()=>{
    // il progetto viene inizialmente creato con i campi url e qgis. Url serve alla mappa per leggere il file di progetto e caricarlo nel campo qgis.
    // Per impostare le settings nel qgis, quindi, bisogna aspettare che la mappa effettui il caricamento. 
    // Questo momento viene intercettato dall'avvaloramento del projectname, come di seguito.
    // Se le settings non sono state ancora impostate, le imposto con i valori di default.
    const Q = new QgisProject(project, setProject)
    if (project.qgis["@projectname"] && Q.getProjectSettings().length===0) {
      
      defaultSettings.forEach(item => {
        Q.setProjectSetting(item[0], item[1])
      })
    }

  }, [project]) //eslint-disable-line

  return(
  <>
  
    <QgisContext.Provider value={[project, setProject]}>
      
      <AppWrapper toolbarType="newProject" className="safer-newProj">

      <ToolManager className="safer-newProj"/>

      <Box 
        sx={{ height:"98%", width:"100%", float:"right"}} 
        content="main" 
        className="safer-newProj"
      >
        <ReflexContainer orientation="vertical" windowResizeAware={true} >
          
          <ReflexElement className="left-pane" size={LeftPanelWidth} minSize={LeftPanelWidth} >
            { isRerUser() ? <DigitalTwinCreatorRER/> : <DigitalTwinCreator/> }
          </ReflexElement>
          
          <ReflexSplitter propagate={true}/>
          
          <ReflexElement className="middle-pane">
            <SaferMap fileqgs={project.url} />
          </ReflexElement>
          
          <ReflexSplitter propagate={true}/>
          
          <ReflexElement className="right-pane" size={RightPanelWidth} minSize={RightPanelWidth}>
            <LayerSwitcher visible={false} style={{ width: 100, borderWidth: 2 }} />
          </ReflexElement>
      
        </ReflexContainer>
      </Box>
      </AppWrapper>
    </QgisContext.Provider>
     
    </>

  ); 
}