import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select} from "@mui/material"
import { QgisContext, QgisProject } from "@SaferPlaces2023/safer-map"
import { useContext, useState } from "react"
import { useEvent, fireEvent } from "../utils/events"
import strftime from "strftime"
import { addjob } from "../utils/addjob"
import { justpath } from "../utils/filesystem"
import { __drive__, getProjectName } from "../utils/const"


export default function Safer001Dialog(props) {

    const [open,  setOpen]  = useState(false)
    const [cosmo, setCosmo] = useState("")
    
    const [project, ] = useContext(QgisContext);
    const Q = new QgisProject(project)
  
    const handleClose = () => {
      setOpen(false)
    }
  
    const handleOpen = () => {
      setOpen(true)
    }

    const handleChange = (event) => {
        setCosmo(event.target.value)
    }

    const handleRun = () => {

        handleClose()

        //const time_lag = edate.diff(bdate, 'days')
        const now = strftime("%H%M%S")
        const filedem = Q.getDemPath(__drive__)
        
        let params = {
            name:        `SF001_${now}`,
            type:        "cosmo",
            project:     getProjectName(),
            cosmo:       "CSKS4_GEC_B_HI_20_HH_RD_SF_20230521165033_20230521165040.h5",
            out:         `${justpath(filedem)}/COSMO_SF001_${now}.tif`,
            note:        `Cosmo-Safer001`,
            mode:        "ecs"
        }
        fireEvent("simulation:start")
        addjob(params)
    } 

    useEvent("cosmo:open", handleOpen)

    return (
        <>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm" >
            <DialogTitle>Cosmo</DialogTitle>
            <DialogContent style={{ paddingTop: "50px"}}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Cosmo File</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={cosmo}
                        label="cosmo file"
                        onChange={handleChange}
                    >
                        <MenuItem value="CSKS4_GEC_B_HI_20_HH_RD_SF_20230521165033_20230521165040.h5">CSKS4_GEC_B_HI_20_HH_RD_SF_20230521165033_20230521165040</MenuItem>
                                                
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                {/* BUTTON CANCEL*/}
                <Button onClick={handleClose} color="error">Cancel</Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button 
                    onClick={handleRun} 
                    color="inherit">
                    Run
                </Button>
            </DialogActions>
          </Dialog>
      </>)
      
}