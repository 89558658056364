import React, { useEffect } from "react";
import { fireEvent, useEvent} from "../utils/events";
import {Typography} from "@mui/material"
import { useState } from "react";
import { statusMessageStyle_success, statusMessageStyle_err, statusMessageStyle_info } from "../themes";


export const MessageBoxShow = (response) => {

    fireEvent("messagebox:show", { text: response?.data?.message||response||"", type: response?.status||"success" })
}

export const StatusMessageBox = () => {

    const timeout = 3000 // msec
    const [statusMessage, setStatusMessage] = useState("")
    const [messageType, setMessageType] = useState("success")
    const [timeoutId, setTimeoutId] = useState(null);

    const handleShow = (event) => {
        setStatusMessage(event.detail.text)
        setMessageType(event.detail.type)
    
        if (timeoutId) clearTimeout(timeoutId)

        let tid = setTimeout(() => {
            setStatusMessage("")
        }, timeout);

        setTimeoutId(tid)
    }

    useEvent("messagebox:show", handleShow)

    useEffect(() => {
        return () => clearTimeout(timeoutId)
    }, [timeoutId])

    let statusMessageStyle = undefined
    switch(messageType){
        case "success":
            statusMessageStyle = statusMessageStyle_success
            break
        case "error":
            statusMessageStyle = statusMessageStyle_err
            break
        default:
            statusMessageStyle = statusMessageStyle_info
    }


    return (
        <Typography style={statusMessageStyle} variant="caption"> 
            {statusMessage} 
        </Typography>
    )
}