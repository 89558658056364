import { juststem } from "./filesystem"

const predefined_job = "(WD_|DMG_)?(?<scenario>P|R|C)(?<rt>\\d+)(?<proj>H|P1|P2)"


export function isValidEmail(email) { return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) }

/**
 * isJobPredefined
 * @param {*} filename 
 * @returns 
 */
export const isJobPredefined = (filename)=>{ //! Fuorviante: Questa non controlla i job ma i file delle simulazioni

    let name = juststem(filename);
    //let re = new RegExp("^(WD_|DMG_)?(P|R|C)(\\d+)(H|P1|P2)", "i");
    let re = new RegExp(predefined_job, "i");
    return re.test(name);

}


/**
 * getJobScenario
 * @param {*} filename 
 * @returns 
 */
export const getJobScenario = ( filename )=>{
    const scenarios = {"P":"Rain","R":"River","C":"Coast"}
    let name = juststem(filename);
    let re = new RegExp(predefined_job, "i");
    let res = re.exec(name);
    return (res)?scenarios[res.groups["scenario"]]:"";
}

/**
 * getJobReturnTime
 * @param {*} filename 
 * @returns 
 */
export const getJobReturnTime = ( filename )=>{    
    let name = juststem(filename);
    let re = new RegExp(predefined_job, "i");
    let res = re.exec(name);
    return (res)?res.groups["rt"]:"";
}

/**
 * getJobProjection
 * @param {*} filename 
 * @returns 
 */
export const getJobProjection = ( filename )=>{   
    const projections = {"H":"Historical","P1":"RCP4.5","P2":"RCP8.5"} 
    let name = juststem(filename);
    let re = new RegExp(predefined_job, "i");
    let res = re.exec(name);
    return (res)?projections[res.groups["proj"]]:"";
}

export const getTmax = (command)=>{
    const re = new RegExp(/--tmax\s+(\d+)/, "i");
    const res = re.exec(command);
    const tmax = (res)?parseInt(res[1])*3600:36000
    return tmax
}

export const getTi = (command)=>{
    const re = new RegExp(/--ti\s+(\d+)/, "i");
    const res = re.exec(command);
    const ti = (res)?parseInt(res[1]):3600
    return ti
}


export const isLast = (filename, tmax)=>{
    if (filename.includes("@")){
        const indexAt = filename.indexOf('@');
        const value = parseInt(filename.substring(indexAt + 1));
        return value >= tmax;
    }
    return true    
}

export const getDelt = (command)=>{
    const re = new RegExp(/--delt\s+(\d+)/, "i");
    const res = re.exec(command);
    const delt = res?parseInt(res[1]):600
    return delt
}

export const getUntrimShots = (item)=>{

    const filenames = []
    // infer tmax from command using regexp
            // es: command = "untrim --dem dem.tif --model untrim --tmax 36000 ..."
    const tmax = getTmax(item.command)

    const ti = getTi(item.command)

    // get --delt from command using regexp
    // es: command = "untrim --dem dem.tif --model untrim --tmax 36000 --delt 3600 ..."
    const delt = getDelt(item.command)
    
    let ts = 0
    while (ts <= tmax+delt){
        if (ts >0 && ts % ti < delt){
            filenames.push(`${item.workdir}/WD_${item.name}@${ts}.tif`)
        }
        ts += delt
    } 
    // reverse filenames
    filenames.reverse()
    return filenames        
}


export const test = ()=>{

    let filenames = [
        "P2H",
        "WD_P5H.tif",
        "WD_C5P1.tif",
        "WD_R100P2.tif",
        "DMG_P5H.tif", 
        "DMG_A5H.tif" //NOT OK
    ];
    for (let filename of filenames){
        console.log(filename,isJobPredefined(filename));
        console.log(getJobScenario(filename), getJobReturnTime(filename), getJobProjection(filename));
    } 

}


