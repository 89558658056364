
import { useContext, useEffect, useState } from "react"
import {
    FormControlLabel, 
    Button, 
    FormHelperText, 
    DialogContentText, 
    DialogContent, 
    Input, 
    InputAdornment, 
    Stack, 
    Radio,
    RadioGroup,
    Grid,
} from "@mui/material"
import { UploadGeodata } from "../widgets/UploadGeodata"
import { QgisContext } from "@SaferPlaces2023/safer-map"
import { juststem } from "../utils/filesystem"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { SelectFile } from "../widgets/SelectFile"
import { fetchFeatures } from "@SaferPlaces2023/esri-shape-file"
import { isString } from "highcharts"
import { getProjectDir } from "../utils/const"

/*************************************************************************************************************
 *     1)   Rain                                                                                             *
 *************************************************************************************************************/
 export const RainFallStep = function({scenario, setScenario, options, onPause}) {

    const [project, ] = useContext(QgisContext)
    const [localRain, setLocalRain] = useState(0)
    
    const handleDurationChange = (event) => {
        setScenario({...scenario, duration:event.target.value})
    }

    // aggiorna la rain uniform settando il volume
    const handleRainChange = (event) => {
        
        setScenario({...scenario, rain: event.target.value} )
    }

    // aggiorna la rain local settando il layer
    const handleModeChange = (event) => {

        const rainMode = event.target.value

        let rainValue = scenario.rain
        if (rainMode === options.local) {
            const project_dir = getProjectDir()
            
            console.log("[project_dir]", project_dir)
            rainValue = `${project_dir}/rainfall.shp`
        } else if (rainMode === options.raster) {
            rainValue = null                        // quando cambio modalità, se non ho un file caricato, la rain deve essere null
        }else if (rainMode === options.safer003) {
            rainValue = ""
        }
        //console.log("rainValue", event.target.value, rainValue)
        //setRain(rainValue)
        setScenario({...scenario, rain:rainValue, rain_mode: event.target.value})             
    }

    const handleChangeRainFName = (fname) => {
        setScenario({...scenario, rain_fname: fname})
    }

    // aggiorna la rain local settando il file caricato
    const handleUploadRainEvent = (event) =>{

        setScenario({...scenario, rain:event.filename, rain_upload:Date.now()} )
        //force to reload the rain layer
        project?.map?.refresh(juststem(event.filename))
    }

    useEffect(() => {
        const calculateRain = async(rain) => {
        if (rain && isString(rain) && rain.includes(".shp")){
            const FeatureCollection = await fetchFeatures(rain);
            const features = FeatureCollection.features||[]
            const totalRain = (features.length===0)?0:features.map(f => parseFloat(f.properties.rain)).reduce((a,b) => a+b)// somma
            return { n: features.length, rain: totalRain }
        }
        return {n:0, rain:0}
        }

        calculateRain(scenario.rain).then(({n, rain}) => {
            setLocalRain(rain)
        })
        
    }, [scenario.activeStep, scenario.pause, scenario.rain, scenario.rain_mode, scenario.rain_upload]) //eslint-disable-line
    
    return (
        <DialogContent>
            
            <DialogContentText component="div">
                Select the type of rain
            </DialogContentText>  

            <Grid container spacing={1}>

                {/* - - - - - - - - - - - - - - - - - - 
                                LEFT PANEL 
                - - - - - - - - - - - - - - - - - - - - - */}
                <Grid item lg={6}>
                        <RadioGroup
                            sx={{marginLeft:5}}
                            value={scenario.rain_mode}
                            onChange={handleModeChange}
                        >
                            <FormControlLabel value={options.uniform} control={<Radio />} label="UNIFORM" />
                            <DialogContent 
                                hidden={scenario.rain_mode !== options.uniform}>
     
                                <Stack spacing={2}>
                                    Total amount of rain event distibuted in a uniform way overall the area of study. <br />
                                    <Input
                                        disabled={scenario.rain_mode !== options.uniform}
                                        value = {isNaN(scenario.rain) ? 0 : scenario.rain} // il valore di rain può essere sia il nome del file che il valore numerico. Solo quest'ultimo va assegnato allo slider
                                        textalign={'right'}
                                        type="number"
                                        endAdornment={<InputAdornment position="end">mm</InputAdornment>}
                                        inputProps={{style:{textAlign:"right"}}}
                                        onChange={handleRainChange}
                                    />
                                    <FormHelperText>Rain</FormHelperText>   
                                </Stack>
                                    
                            </DialogContent>

                            <FormControlLabel value={options.local} control={<Radio />} label="NON-UNIFORM (draw)" />
                            <DialogContent
                                hidden={scenario.rain_mode !== options.local}>
                               
                                <Stack spacing={2}>
                                    Rain volume from local events: {localRain} mm
                                    
                                    <Button variant="contained" 
                                        color="primary"
                                        disabled={scenario.rain_mode!== options.local}
                                        onClick={onPause}
                                        startIcon={<AddCircleOutlineIcon />}
                                        >
                                        Add rainfall event
                                    </Button>
                                </Stack>
                            </DialogContent>

                            <FormControlLabel value={options.raster} control={<Radio/>} label="NON-UNIFORM (upload)" />
                            <DialogContent
                                hidden={scenario.rain_mode !== options.raster}>
                                
                                <Stack>
                                    Select a rain file in raster/shape format
                                    <UploadGeodata
                                        disabled={scenario.rain_mode!== options.raster}
                                        datatype = "geodata"
                                        cmap = "rainfall"
                                        t_srs = {project.qgis.mapcanvas.destinationsrs.spatialrefsys.authid}   
                                        onUpload={handleUploadRainEvent}
                                        onChangeField={handleChangeRainFName} 
                                        shapefile_selector_label = "Choose the field for rain data"
                                        />
                                </Stack>
                            </DialogContent>

                            <FormControlLabel value={options.safer003} control={<Radio/>} label="SAFER003" />
                            <DialogContent 
                                hidden={scenario.rain_mode !== options.safer003}>
                                <SelectFile 
                                    type="raster" 
                                    tags="rainfall"   //use a tag for filtering the list of files
                                    value={scenario.rain} 
                                    onChange={handleRainChange}  
                                    />
                                <FormHelperText>Rain from Safer003</FormHelperText>
                            </DialogContent>
                            
                        </RadioGroup>                    
                </Grid>

                <Grid item lg={4}>
                    <DialogContent>
                        Total duration of the rainfall event
                        <br />
                        <Input
                            type="number"
                            value = {scenario.duration}
                            textalign={'right'}
                            endAdornment={<InputAdornment position="end">h</InputAdornment>}
                            aria-describedby="standard-duration-helper-text"
                            inputProps={{'aria-label': 'duration', style:{textAlign:"right"}}}
                            onChange={handleDurationChange}
                        />
                        <FormHelperText id="standard-duration-helper-text">Duration</FormHelperText>
                    </DialogContent>
                </Grid>
                
            
            </Grid> 
            
        </DialogContent>  
    )
}