import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  ListItemText,
  ListItemIcon,
  List,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemButton,
  DialogActions,
  Button,
  IconButton,
  Tooltip
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { addLayerToProject, QgisContext, QgisProject, Tools } from "@SaferPlaces2023/safer-map";
import {
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon, 
  Layers as LayersIcon }  from "@mui/icons-material";
import { juststem } from "../utils/filesystem";
import * as requests from '../utils/requests';
import { useEvent } from "../utils/events";
import { ToolsContext } from "../widgets/ToolManager";


export default function InfiltrationLayersDialog () {

  const [, setActiveTool] = useContext(ToolsContext)
  const [project, ] = useContext(QgisContext)
  const [layers, setLayers] = useState([]) 
  const [open, setOpen] = useState(false)
  const Q = new QgisProject(project)

  
  // compute infiltration layers every time the dialog opens
  useEffect(() => {
    if (project.map && open){
      let maplayers = Q.getMapLayersByTag("type", "infiltration_rate").map(maplayer => maplayer.layername)  //array di stringhe
      setLayers(maplayers)
    }
  // eslint-disable-next-line 
  }, [open, project]);

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  /**
   * Duplicate infiltration layer
   * @param {string} layerName 
   */
  const handleCopyLayer = (layerName) => {
    let fileName = layerName + ".shp"
    let newLayerPath = ""
    let fieldName = Q.getInfiltrationFieldName()
    requests.duplicateLayer(project.projectname, fileName, "infiltration_rate", fieldName)
    .then((response) => {
      if (response.data.status === "success") {
        newLayerPath = response.data.data
        addLayerToProject({
          project:project, 
          filename:newLayerPath, 
          groupname:"Digital Twin", 
          visible:true, 
          zoomToLayer:false, 
          permanent:true,
          cmapname:"infiltration_rate",
          fieldName: fieldName || "PERM",
          overwrite:true
        })
      }
    })
    .then(() => {
      setLayers([...layers, juststem(newLayerPath)]) // remove from list
    })
  }

  /**
   * Delete infiltration layer
   * @param {string} layer Layer name 
   * */
  const handleDeleteLayer = (layer) => {
    let fileName = layer + ".shp"
    requests.deleteLayer(project.projectname, fileName)
    .then((response) => {
      if (response.data.status === "success") {
        Q.removeLayer(layer, false)
      }
    })
    .then(() => {
      setLayers(layers.filter((name) => name !== layer)) // remove from list
    })
  }


  // /** select the working layer and activate 
  //  * the infiltration tool on it 
  //  * @param {string} layer Name of the layer */
  // const handleSelect = (layerName) => {
  //   setSelectedLayer(layerName)
  //   let layer = project.map.getLayerByName(layerName)
  //   project.map.removeTool("InfiltrationSelect")
  //   project.map.activateTool("InfiltrationSelect", true, layer)
    
  //   // hide all the infiltration layers and show the selected one
  //   layers.forEach(name => {
  //     Q.setVisible(name, (name === layerName))
  //   }); 
  //   handleClose()
  // }

  const handleSelect = (layerName) => {
    setActiveTool({
      name:       Tools.INFILTRATION_SELECT, 
      layerName:  layerName // layer scelto dall'utente
    })
    handleClose()
  }

  useEvent("infiltration-tool:activate", handleOpen)

  return <>
    <Dialog open={open} maxWidth="lg" fullWidth PaperProps={{sx: {height: 400, width: 500}}}>
      <DialogTitle>Infiltration Layers</DialogTitle>
      <Divider orientation="horizontal" flexItem />

      <DialogContent>
        <List>
          { 
            layers.length ?
            layers.map((item, i) => {
              return (
                <ListItem key={item}
                  secondaryAction={
                    <>
                      <Stack direction="row">
                        
                        <Tooltip title="Duplicate layer">
                          <IconButton onClick={() => handleCopyLayer(item)}> <ContentCopyIcon/> </IconButton>
                        </Tooltip>
                        
                        <Tooltip title={i > 0 ? "Delete layer" : "The original layer cannot be deleted"}>
                          <IconButton  onClick={ i > 0 ? () => handleDeleteLayer(item) : null}> 
                            <DeleteIcon color={i > 0 ? "error" : "disabled"} /> 
                          </IconButton>
                        </Tooltip>
                        
                      </Stack>
                    </>
                }>
                  <ListItemButton onClick={() => handleSelect(item)}>
                    {/* layer icon */}
                    <ListItemIcon>
                      <LayersIcon />
                    </ListItemIcon>
                  
                    {/* layer name */}
                    <ListItemText >
                      {item}
                    </ListItemText>
                  </ListItemButton>
                  
                    
                </ListItem>
                )
            })
            :
            <ListItemText>
              The project has no infiltration-rate layer
            </ListItemText>
            
          }
        </List>
      </DialogContent>

      {/* dialog action */}
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">Close</Button>
      </DialogActions>
    </Dialog>
  </>
}
