import React from "react";
import { useNavigate } from 'react-router-dom';
import { CardMedia, Card, CardHeader, LinearProgress, Typography, Chip, CardContent } from "@mui/material";
import { BigTooltip as Tooltip } from "../widgets/mui/BigTooltip";
import ContextMenu from "./ContextMenu"
import { preventDefault } from "../utils/events";
import { Warning as WarningIcon, Lock as LockIcon}  from '@mui/icons-material';
import Paths from "../utils/paths";
import { justfname } from "../utils/filesystem";
import { fireEvent } from "../utils/events";


//export default function ProjectCard({ projectname, date, disabled, shared_with, warning }) {
export default function ProjectCard(props) {
  
  const projectname = props.project_name
  const linkname = justfname(props.project_link)
  const last_update = props.last_update
  const disabled = props.disabled
  const shared = props.shared
  const owner = props.owner
  const warning = props.warning
  const locked = props.locked
  const locked_by = props.locked_by
  const imageUrl = props.image_url
  const tags = props.tags 

  //const imageUrl = `/api/resource/${projectname}/screenshot.png`
  //const imageUrl = `/projects/${username}/${projectname}/screenshot.png`
  const navigate = useNavigate()
  
  const progressBarVariant = (disabled) ? "indeterminate" : "determinate"

  const openProject = () => {
    if (!disabled && !locked){
      // fetch(`/projects/${username}/${projectname}/project.lock`).then(response => response?.text())
      // .then(text => {text.startsWith("<!DOCTYPE html>"))
      // console.log(text)
      // if (!text)
      navigate(`${Paths.PROJECT_PAGE}?projectname=${projectname}&linkname=${linkname}`)
    }
  }

  const handleRemoveTag = (tagname) => {

    fireEvent("event:removetag", {"projectname":linkname, "tagname":tagname})
  }

  const headerColor = (shared, locked) =>{
    if (locked) return "#AAAAAAAA"
    if (shared) return "#138535AA"
    return undefined
  }

  return (
    <Card 
      key = {props.id}
      onContextMenu={preventDefault}  
      elevation={6} 
      sx = {{
        position:"relative",
        minHeight:"20%", 
        minWidth:"40%", 
        color:(disabled)?"gray":"black", 
        opacity:(disabled)?0.5:1,
      }}
      
    >
      <CardHeader
        sx = {{ backgroundColor: headerColor(shared, locked) }}
        action={ <ContextMenu {...props} /> }
        title={
          <>
            <Typography variant="h6" onClick={openProject} style={{cursor:"pointer"}}>
              {locked && <Tooltip title={`${locked_by} is working on this project`}><LockIcon/></Tooltip>} {projectname}
              {warning && <WarningIcon color="warning"/>}
            </Typography>
            
          </>
        }
        subheader={
          <>
            <Typography>{last_update}</Typography>
            <Typography variant="caption">{owner}</Typography>
          </>
        }
      />
      <LinearProgress variant={progressBarVariant} value={100} />
      <CardMedia 
          onClick={openProject}
          component="img"
          height="200"
          image={imageUrl}
      />
      <CardContent sx={{position: "absolute", bottom: 0}}>
        {tags?.map( tag => <Chip key={`tag-${tag}`} label={tag} color="primary" onDelete={()=>handleRemoveTag(tag)} sx ={{marginRight:"5px", marginBottom:"5px"}}/>)}
      </CardContent>
    </Card>
  );
}
