import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { QgisContext, QgisProject } from "@SaferPlaces2023/safer-map"
import dayjs from "dayjs"
import { useContext, useState } from "react"
import { useEvent, fireEvent } from "../utils/events"
import strftime from "strftime"
import { addjob } from "../utils/addjob"
import { __drive__, getProjectName } from "../utils/const"
//import { justpath } from "../utils/filesystem"


export default function Safer003Dialog(props) {

    const [open,  setOpen]  = useState(false)
    const [bdate, setBDate] = useState(dayjs("2022-11-22"))
    const [time_lag, setTimeLag] = useState(1)
    const [time_step, setTimeStep] = useState("1hrly")

    const [project, ] = useContext(QgisContext);
    const Q = new QgisProject(project)
  
    const handleClose = () => {
      setOpen(false)
    }
  
    const handleOpen = () => {
      setOpen(true)
    }

    const handleRun = () => {

        handleClose()

        const now = strftime("%H%M%S")
        
        let params = {
            name:        `SF003_${now}`,
            type:        "safer003",
            project:     getProjectName(),
            bbox:        Q.getDemPath(__drive__),
            start:       bdate.format("YYYY-MM-DD"),
            time_lag:    time_lag,
            time_step:   time_step,
            note:        `Safer003`,
            mode:        "local"
        }
        fireEvent("simulation:start")
        addjob(params)
    }

    const handleBDateChange = (date) => {
        setBDate(date)
    }

    const handleTimeLagChange = (event) => {
        setTimeLag(Number(event.target.value))
    }

    const handleTimeStepChange = (event) => {
        setTimeStep(event.target.value)
    }

    useEvent("safer003:open", handleOpen)

    return (
        <>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm" >
            <DialogTitle>Safer003</DialogTitle>
            <DialogContent style={{ paddingTop: "50px"}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    
                <DatePicker 
                    label="from date"
                    format="DD/MM/YYYY" // Set your desired date format here
                    value={bdate} 
                    onChange = {handleBDateChange}
                />
                <FormControl variant="outlined" sx={{ width: "100px" }}>
                    <InputLabel id="time-lag-label">lag (days)</InputLabel>
                    <Select
                        labelId="time-lag-label"
                        variant="outlined"
                        label="lag (days)"
                        value={time_lag}
                        onChange={handleTimeLagChange}
                        sx = {{ width: "100px"}}
                        >
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                    </Select>
                </FormControl>
                
                <FormControl variant="outlined" sx={{ width: "100px" }}>
                    <InputLabel id="time-lag-label">step</InputLabel>
                    <Select
                        labelId="time-lag-label"
                        variant="outlined"
                        label="step"
                        value={time_step}
                        onChange={handleTimeStepChange}
                        sx = {{ width: "100px"}}
                        >
                        <MenuItem value="1hrly">1hrly</MenuItem>
                        <MenuItem value="3hrly">3hrly</MenuItem>
                        <MenuItem value="6hrly">6hrly</MenuItem>
                        <MenuItem value="daily">daily</MenuItem>
                        <MenuItem value="monthly">monthly</MenuItem>
                        <MenuItem value="yearly">yearly</MenuItem>
                    </Select>
                </FormControl>


                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                {/* BUTTON CANCEL*/}
                <Button onClick={handleClose} color="error">Cancel</Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button 
                    
                    onClick={handleRun} 
                    color="inherit">
                    Run
                </Button>
            </DialogActions>
          </Dialog>
      </>)
      
}