import { useState, useEffect, useContext } from "react"

import {  
  Button, 
  DialogContentText, 
  DialogContent,
  Stack,
  Grid,
  InputAdornment,
  Input,
  FormHelperText,
  Box,
} from "@mui/material"


import { fetchFeatures } from "@SaferPlaces2023/esri-shape-file";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { UploadGeodata } from "../widgets/UploadGeodata";
import { QgisContext } from "@SaferPlaces2023/safer-map";
import { juststem } from "../utils/filesystem";
import { isString } from "../utils/strings";



/*************************************************************************************************************
 *     1)   River                                                                                             *
 *************************************************************************************************************/
export const RiverEventStep = function ({scenario, setScenario, onPause}) {
    
    const [rpoints, setRPoints] = useState(0)
    const [totalVolume, setTotalVolume] = useState(0)
    const [project, ] = useContext(QgisContext)

    const handleDurationChange = (event) => {
      setScenario({...scenario, duration:event.target.value} )
    }

    const handleUploadRiverEvent = (event) => {
    
      setScenario({...scenario, rain:event.filename, rain_upload:Date.now()} )
      //force to reload the rain layer
      project?.map?.refresh(juststem(event.filename))
    }

    useEffect(() => {
      const calculateVolume = async(rain) => {
        if (rain && isString(rain) && rain.includes(".shp")){
          const FeatureCollection = await fetchFeatures(rain);
          const features = FeatureCollection.features||[]
          const totalVolume = (features.length===0)?0:features.map(f => parseFloat(f.properties.v)).reduce((a,b) => a+b)// somma
          return { n: features.length, v: totalVolume }
        }
        return {n:0, v:0}
      }

      calculateVolume(scenario.rain).then(({n, v}) => {
        setRPoints(n)
        setTotalVolume(v)
      })
     
    }, [scenario.activeStep, scenario.pause, scenario.rain, scenario.rain_upload]) //eslint-disable-line




    return (
      <>
      <DialogContent>
        <Grid container spacing={2}>

          <Grid item lg={6}>
              <Stack spacing={2}>

                <DialogContentText component="div">
                  <Stack direction="row" justifyContent="space-between">
                    <Box> Number of river spilling points: </Box>
                    <Box>{rpoints}</Box>
                  </Stack>
                  
                  <Stack direction="row" justifyContent="space-between">
                    <Box>Volume of water from spilling points [m&sup3;]: </Box>
                    <Box>{totalVolume.toFixed(0)}</Box>
                  </Stack>
                </DialogContentText>  

                <Button variant="contained" 
                      color="primary" 
                      onClick={onPause}
                      startIcon={<AddCircleOutlineIcon />}
                      >
                      Add river-event
                </Button>

                <DialogContentText component="div">
                  <Stack direction="row" justifyContent="space-between">
                    <Box>or upload a file with river-event points with a volume field 'v' [m&sup3;]:</Box>
                  </Stack>
                  <UploadGeodata 
                    onUpload={handleUploadRiverEvent}
                    //onChangeField={handleSandFieldChanged}
                    datatype="vector" 
                    geometry_type={["point",]}
                    //fieldSelector
                    mandatory_fields={["v"]}
                    cmap = "riverevent"
                    />
                </DialogContentText>

              </Stack>
          </Grid>

          <Grid item lg={6}>
            <DialogContent>
              Total duration of the river event
              <br /><small>Used with model UnTrim</small><br /> 
                  
                  <Input
                      type="number"
                      value = {scenario.duration}
                      textalign={'right'}
                      endAdornment={<InputAdornment position="end">h</InputAdornment>}
                      inputProps={{'aria-label': 'duration', style:{textAlign:"right"}}}
                      onChange={handleDurationChange}
                  />
                  <FormHelperText>Duration</FormHelperText>
                  
              </DialogContent>
          </Grid>

        </Grid>

      </DialogContent>

     
      </>
    );
  };
  
  