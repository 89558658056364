import { useContext } from "react";
import { fireEvent, useEvent } from "./utils/events";
import { QgisContext, QgisProject} from "@SaferPlaces2023/safer-map";
import { api_features, project_delete, user_delete, user_get, user_post } from "./utils/http";
import { MessageBoxShow } from "./widgets/StatusMessageBox";
import { len } from "./utils/strings";
import { juststem } from "./utils/filesystem";
import { saveProject } from "./utils/requests";


export function EventManager() {
  
  const [project, ] = useContext(QgisContext); 
  
  const handleSaveProject = (event) => {
    
    const project = event.detail.project;
    const clean = event.detail.clean;
    const Q = new QgisProject(project);

    // Save the project [SAVE]

    // Save on event project:save 
    return saveProject({xml: Q.ExportToXml(), overwrite: true, clean:clean}).then(response=>{
      MessageBoxShow(response)}
    )
  }

  const handleFeaturesUpdate = (event) => {
    
    const params = { 
      ...event.detail, 
      //project: project.projectname
    }

    return api_features(params).then(response => {
    
      // Read featurecollection     
      const layername = juststem(response.filename)
      const extent = response.FeatureCollection?.extent 
      const features = response.FeatureCollection?.features || []
      //update the fid for new features
      if (layername && features && len(features) > 0) {
          const fid = features[0].id
          project.map.updateFeatureFID(layername, fid)
      }
      //update the extent
      if (extent) {
        const Q = new QgisProject(project);
        const maplayer = Q.getMapLayer(layername)
        maplayer.extent = extent
      }
    })//end then
  }

  const handleFeaturesDelete= (event) => {
    const params = {
      ...event.detail,
    }
    project_delete("/api/features", params).then((response) => {
      response = response.data 
      response.layername = juststem(response.data)  //response.data = filename
      project.map.removeFeatures(response.layername, response.fids)
      project.map.refresh(params.layername)
    });
  }

  const handleFDamageRead = () => {
    user_get("/api/fdamage/all?type=damage", {}).then((response) => {
       // response = [{"id":0,"name":"fun1","type":"damage","d1":1.0,"d2":2.0,"d3":3.0, ...},...]
       if (response && response.data)
       fireEvent("fdamage:all", response.data)
    });
  }

  const handleFDamageDelete = (event) => {
    user_delete("/api/fdamage/"+event.detail.id).then(() => {
      fireFdamageAll()
    })
  }

  /* refreshes the fdamage list */
  const fireFdamageAll = () => {
    user_get("/api/fdamage/all?type=damage", {}).then((response) => {
      fireEvent("fdamage:all", response.data)
    })
  }

  //save the fdamage points
  const handleFDamageSave = (event) => {
    user_post(`/api/fdamage/save`, event.detail).then(() => {
      fireFdamageAll()
    })
  }

  useEvent("project:save",  handleSaveProject);
  
  useEvent("feature:update", handleFeaturesUpdate);
  useEvent("feature:delete", handleFeaturesDelete);

  useEvent("fdamage:read", handleFDamageRead);
  useEvent("fdamage:save", handleFDamageSave);
  useEvent("fdamage:delete", handleFDamageDelete);

  


  return null;
}
