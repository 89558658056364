import { useState } from "react";
import { Button, CircularProgress } from "@mui/material";


const EavyButton = (props) => {

    const { children, onClick, ...rest } = props;
    const [loading, setLoading] = useState(false);
    
    return (<Button 
        variant="contained" 
        color="primary"
        disabled={loading}
        onClick={(params) => {
            setLoading(true)
            onClick(params)?.then(() => setLoading(false))
        }} 
        {...rest}
        >
        {loading?<CircularProgress color="secondary" size={16}/>:null}
        {children}
    </Button>)


}//end EavyButton

export default EavyButton;