import React, { useContext, useState } from "react";
import {IconButton, Grid} from "@mui/material"
import { BigTooltip as Tooltip } from "./mui/BigTooltip";
import {useNavigate} from "react-router-dom"
import Paths from "../utils/paths";
import { ArrowBack } from '@mui/icons-material';
import { SearchBox } from "@SaferPlaces2023/sui-vite"
import { QgisContext, QgisProject } from "@SaferPlaces2023/safer-map";
import { len } from "../utils/strings";
import { isRerUser } from "../utils/http";
import { RER_RIVERS } from "../utils/const";


export const NewProjectTools = () => {

    let navigate = useNavigate()
    const [search, setSearch] = useState("")
    const [selected, setSelected] = useState("") // eslint-disable-line
    const [project, setProject ] = useContext(QgisContext)
    const Q = new QgisProject(project, setProject)

    const extent = Q.getExtent("EPSG:4326")
    const lon = len(extent)===4 ? extent[0] : undefined 
    const lat = len(extent)===4 ? extent[1] : undefined 

    const handleBack = () => {
        navigate(Paths.MY_PROJECTS)
    }

    const handleSearchTextChange = (event) => {

        setSearch(event.target.value)
    }

    const handleSearchSelect = (option) => {
        setSelected(option);
        const delta = 0.01
        let extent = null
        
        if (option?.properties?.extent){
            extent = option.properties.extent
            extent = [extent[0]-delta, extent[1]-delta, extent[2]+delta, extent[3]+delta]
        }else if (option?.geometry?.coordinates && len(option.geometry.coordinates)===2){
            extent = option.geometry.coordinates
            extent = [extent[0]-delta, extent[1]-delta, extent[0]+delta, extent[1]+delta]
        }

        if (extent){
            Q.zoomToExtent(extent, "EPSG:4326")
        }
    }

    
    const datasource = isRerUser() ? Q.getMapLayer(RER_RIVERS)?.datasource : undefined
    
    return (

        <Grid container justifyContent="flex-end" columnSpacing={3} >

            <Grid item className="back-from-newProj">
                <Tooltip title="Back to My Projects">
                    <IconButton onClick={handleBack} variant="contained" color="primary"> 
                        <ArrowBack />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item style={{ width:"400px"}}>
                <SearchBox
                    sx = {{width: 250}}
                    style={{position:'relative', top:0, left:'4px', verticalAlign:'middle',padding:0, margin:0}} 
                    
                    label="Search"
                    value={search}
                    onChange={handleSearchTextChange}
                    placeholder="Search"
                    onSelect={handleSearchSelect}

                    lon = {lon}
                    lat = {lat}
                    //tags={["house", "street", "locality", "district", "city", "county", "state", "country"]}
                    //tags={["locality", "district", "city", "county", "state", "country"]}
                    
                    //layer = "/vsicurl/https://s3.us-east-1.amazonaws.com/saferplaces.co/eedem/WORLD/ITALY/REGIONE-EMILIA-ROMAGNA/bacini-idrografici/Bacini_DQ60_PdG_21_E32_v3.shp|layer=Bacini_DQ60_PdG_21_E32_v3"
                    layer = {datasource}
                />
            </Grid>
           
        </Grid>

    )
}