
import { project_delete, project_post, user_delete, user_get, user_post, user_put } from "./http"
import { listify } from "./strings"


export const listProjects = (tags) => {
    return user_get("/api/projects", { "filter": listify(tags) }).catch((e) => {
    //return user_get("/api/projects").catch((e) => {
        console.log("[ERROR]: " + e)
    })
}

export const deleteProject = (projectname) => user_delete("/api/project", {"projectname": projectname})


export const renameProject = (projectName, newProjectName) => {
    return user_put("/api/project", {
        name: projectName,
        newname: newProjectName,
      })
}

export const duplicateProject = (projectName) => {
    // the absence of newname make a copy instead a rename
    return user_put("/api/project", {name: projectName}) 
}

export const shareProject = (params) => {
    if (params.name && params.sharewith) {
        return user_put("/api/project", {name: params.name, sharewith: params.sharewith})
    }
}

/**
 * saveProject
 * @param {*} params 
 * @returns 
 */
export const saveProject = (params) =>{
    
    // xml
    // name
    // overwrite
    // clean
    return user_post("/api/project", params).then((response) => response.data)
}

export const list_contacts = async(tag) => {
    if (tag) {
        const response = await user_get("/api/contacts", {tag: [tag]})
        if (response && response.data && response.data.contacts) {
            return response.data.contacts
        }
    }
    return [];
}

export const list_developers = async() => {
   
    const response = await user_get("/api/contacts", {tag: ["DEV"]})
    if (response && response.data && response.data.contacts) {
        return response.data.contacts
    }
}

export const getReferenceSystem = (bbox) => {
    //let params = new URLSearchParams(bbox.map((coord) => ["bbox", coord])).toString()
    //return user_get("/api/projections?" + params)
    return user_get("/api/projections", {bbox: bbox} )
}

export const getSources = (bbox) => {
    //let params = new URLSearchParams(bbox.map((coord) => ["bbox", coord])).toString()
    //return user_get("/api/sources?" + params)
    return user_get("/api/sources", {bbox: bbox} )
}


/**
 * Request data from nominatim.openstreetmap.org. 
 * @param {*} inputValue The name of the geographic area you want to retrieve information for 
 * @returns a Promise object. The response information is contained in the field "data"
 */
export const fetchSearchOptions = (inputValue) => {
    let url = new URL("https://nominatim.openstreetmap.org/search");
    url.search = new URLSearchParams([["format", "json"],["q", inputValue],["featureType","city"]]).toString();
    return user_get(url)
}




/**
 * @returns A response containing the current usage of 
 * ram and cpu (in field .data as a dictionary {cpu: ... , ram: ...})
 */
export const getResourcesUsage = () => {
    return user_get("/api/cpu")
}


/**
 * Delete a layer from a project
 * @param {string} project Project name
 * @param {string} layer File name
 */
export const deleteLayer = (project, layer) => {
    const params = {projectname: project, filename: layer}
    return project_delete("/api/file", params)
}

/**
 * Duplicate a layer inside a project
 * @param {*} project Project
 * @param {string} filename Name of the file associated with the layer
 * @param {string} cmapname  (Optional) Name of the color map (e.g. "infiltration_rate")
 * @param {string} fieldname (Optional) Name of the field associated to the layer. Only used in case of vector files
 * @returns An object Response. The name of the new file is stored in the the attribute data.data
 */
export function duplicateLayer(project, filename, cmapname="", fieldname="") {
    const params = {projectname: project, filename: filename, cmapname: cmapname, fieldname: fieldname} // TODO shold we move this cmap in an external control? to keep this function general for "duplicating files"
    return project_post("/api/file", params)
}
