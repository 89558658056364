import aws_lamda from "./aws-lambda.svg"
import aws_batch from "./aws-batch.svg"
import local from "./python.svg"
import saferplaces from "./saferplaces.png"
import React from "react";
import { CircularProgress } from "@mui/material";

export const LamdaIcon = () => <img alt="" height="16" width="16" src={aws_lamda}/>

export const BatchIcon = () => <img alt="" height="16" width="16" src={aws_batch}/>

export const LocalIcon = () => <img alt="" height="16" width="16" src={local}/>

export const SaferPlacesIcon = () =>  <img alt="" height="16" width="16" src={saferplaces}/>

export const LoadingIcon = () => <CircularProgress  size={16} variant="indeterminate"/>

export function RasterIcon(){
    return (
        <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
          <path d="m6.333 2h4.334v4.333h-4.334z" fill="#aec7e2"/>
          <path d="m2 2h4.333v4.333h-4.333zm4.333 4.333h4.334v4.334h-4.334zm4.334-4.333h4.333v4.333h-4.333z" fill="#2b3b4d"/>
          <path d="m2 6.333h4.333v4.334h-4.333zm8.667 0h4.333v4.334h-4.333zm-4.334 4.334h4.334v4.333h-4.334z" fill="#aec7e2"/>
          <path d="m2 10.667h4.333v4.333h-4.333z" fill="#2b3b4d"/>
        </svg>
      )
  }//end function
  
  export function PointLayerIcon(){
    return (
        <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="#eeeeec" fillRule="evenodd" stroke="#888a85" strokeLinecap="round" strokeLinejoin="round" transform="translate(0 -16)"><path d="m4.5 12.5c0 .552285-.4477153 1-1 1s-1-.447715-1-1 .4477153-1 1-1 1 .447715 1 1z" transform="translate(0 16)"/><path d="m4.5 12.5c0 .552285-.4477153 1-1 1s-1-.447715-1-1 .4477153-1 1-1 1 .447715 1 1z" transform="translate(2 9)"/><path d="m4.5 12.5c0 .552285-.4477153 1-1 1s-1-.447715-1-1 .4477153-1 1-1 1 .447715 1 1z" transform="translate(9 6)"/></g></svg>
      )
  }//end function
  
  
  export function LineLayerIcon(){
    return (
        <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg"><g stroke="#888a85" strokeLinecap="round" strokeLinejoin="round" transform="translate(0 -16)"><path d="m1.5 4.5 4 9 5-11h4" fill="none" transform="translate(0 16)"/><g fill="#eeeeec" fillRule="evenodd"><path d="m4.5 12.5c0 .552285-.4477153 1-1 1s-1-.447715-1-1 .4477153-1 1-1 1 .447715 1 1z" transform="translate(2 17)"/><path d="m4.5 12.5c0 .552285-.4477153 1-1 1s-1-.447715-1-1 .4477153-1 1-1 1 .447715 1 1z" transform="translate(11 6)"/><path d="m4.5 12.5c0 .552285-.4477153 1-1 1s-1-.447715-1-1 .4477153-1 1-1 1 .447715 1 1z" transform="translate(7 6)"/><path d="m4.5 12.5c0 .552285-.4477153 1-1 1s-1-.447715-1-1 .4477153-1 1-1 1 .447715 1 1z" transform="translate(-2 8)"/></g></g></svg>
      )
  }//end function
  
  export function PolygonLayerIcon(){
    return (
        <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
          <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="4.5" x2="6.5" y1="3.5" y2="10.5">
            <stop offset="0" stopColor="#eee"/><stop offset="1" stopColor="#cfcfcf"/>
          </linearGradient>
          <path d="m.5 6.5c0 12 6 2 9 2 2 0 6 4 6-1 0-8-4.398262-3.1905055-7-4-1.9423402-.604332-8-4-8 3z" fill="url(#a)" fillRule="evenodd" stroke="#888a85" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
  }//end function
  
  export function GroupLayerIcon(){
    return (
        <svg height="24" viewBox="0 0 6.35 6.35" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="#eeeeec" stroke="#888a85" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".265"><path d="m.661 2.778h2.91v2.91h-2.909z"/><path d="m1.72 1.72h2.91v2.91h-2.91z"/><path d="m2.778.661h2.91v2.91h-2.91z"/></g></svg>
      )
  }//end function
  