import {
  IconButton,
  Typography,
  Collapse,
  Grid,
} from "@mui/material";
import { BigTooltip as Tooltip } from "../widgets/mui/BigTooltip";
import React, { useState, useContext } from "react";

import {
  QgisContext,
  QgisProject,
  addLayerToProject,
} from "@SaferPlaces2023/safer-map";
import { getTmax, isLast} from "../utils/regexp";
import { removejob, listjob } from "../utils/addjob";
import { humantime } from "../utils/time";
import { b64decode, len, listify } from "../utils/strings";
import { useInterval } from "../utils/useinterval";
import { useConfirmDialog } from "react-mui-confirm";

import {
  Delete,
  HelpOutlineOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";

import { LamdaIcon, BatchIcon, LocalIcon } from "../icons/MapIcons";
import { justfname, justpath, juststem } from "../utils/filesystem";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import { getJobProjection, getJobReturnTime, getJobScenario, getUntrimShots, isJobPredefined } from "../utils/regexp";
import { __drive__ } from "../utils/const";

const __DELAY__ = 3000;
/**
 * IconJob
 * @param {*} props 
 * @returns 
 */
function IconJob(props) {
  if (props.mode === "local")       return <LocalIcon style={{ width: 20 }} />;
  else if (props.mode === "lambda") return <LamdaIcon style={{ width: 20 }} />; 
  else if (props.mode === "batch")  return <BatchIcon style={{ width: 20 }} />;  
  else if (props.mode === "ecs")    return <BatchIcon style={{ width: 20 }} />; 
  else                              return <HelpOutlineOutlined style={{ width: 16 }} />;
}

function wellKnownCmapName(item){

  let cmapname = undefined
  if (item.scenario === "safer001")
    cmapname="watermask"
  else if (item.type === "cosmo")
    cmapname="watermask"
  else if (item.scenario === "safer003")
    cmapname="rainfall"
  else if (item.filename.includes("watersheds.labels"))
    cmapname="watersheds"
  else if (item.filename.includes("bluespots.labels"))
    cmapname="bluespots"
  else if (item.filename.includes("streams"))
    cmapname="streams"
  else if (item.scenario.includes("damage") || item.filename.includes("DMG_"))
    cmapname="damage"

  return cmapname
}

/**
 * groupNameBy
 * @param {*} scenario 
 * @returns 
 */
function groupNameBy(item){
  
  let scenario = item.scenario;
  let filename = item.filename;
  let layername = juststem(filename).split("@")[0].split(".")[0];
  let groupname = "";

  if (isJobPredefined(filename)){
    let sc = getJobScenario(filename);
    let rt = getJobReturnTime(filename);
    let proj = getJobProjection(filename);
    groupname = `Predefined Scenarios/${sc}/${proj}/RT${rt}`
  } else if (scenario.includes("damage") || filename.includes("DMG_")){
    groupname = "Damage";
  } else if (scenario === "safer_rain_preprocess"){
    groupname = "Bluespots"
  } else if (scenario === "safer_rain_flooding") {
    groupname = "Rain";
    if (item.filename.endsWith("max.tif")) {
      groupname = `Rain/${layername}`
    }else if (item.filename.endsWith("mask.shp")) {
      groupname = `Rain/${layername}`
    }else if (item.command.includes("--model untrim")){
      groupname = `Rain/${layername}`
    }
  } else if (scenario === "safer_river_flooding") {
    groupname = "River";
    if (item.filename.endsWith("max.tif")) {
      groupname = `River/${layername}`
    }else if (item.filename.endsWith("mask.shp")) {
      groupname = `River/${layername}`
    }else if (item.command.includes("--model untrim")){
      groupname = `River/${layername}`
    }
  } else if (scenario === "safer_coast_flooding") {
    groupname = "Coast";
    if (item.command.includes("--model untrim")){
      groupname = `Coast/${layername}`
    }
  } else if (scenario === "safer001") {
    groupname = "Safer001";
  } else if (scenario === "safer002") {
    groupname = "Digital Twin";
  } else if (scenario === "safer003") {
    let filename = juststem(item.filename)
    let arr = filename.split(" ")
    groupname = `Safer003/${arr[0]}`;
  } 
  //console.log(groupname)
  return groupname;
}

function elapsedTime(starttime, endtime) {
  return (endtime ? Date.parse(endtime) : Date.now()) - Date.parse(starttime)
}

/**
 * visibilityByFileName
 * @param {*} filename 
 * @returns 
 */
function visibilityByFileName(item){
  let filename = item.filename;
  let layername = juststem(filename);
  let visible = false;
  // ... (WD_P)2H
  // ... (WD_C)
  // if re.match(r"^(WD_P|WD_C)", filename):
  // 
  if (isJobPredefined(filename)){
    visible = false;
  } else if (item.command.includes("untrim")) {
    const tMax = getTmax(item.command);
    visible = isLast(layername, tMax);
    visible = filename.endsWith("max.tif") || filename.endsWith("mask.shp") ? false : visible;
  } else if (layername.startsWith("WD_")) {
    visible = true;
  } else if (layername.startsWith("DMG_")) {
    visible = true;
  } else if (item.scenario === "safer001") {
    visible = true;
  } else if (item.scenario === "safer002") {
    visible = true;
  }
  return visible;
}

/**
 * layerAreadyExists - check also datasource
 * @param {*} project 
 * @param {*} id 
 * @returns 
 */
const layerAreadyExists = (project, id) => {

    const map = project?.map;

    if (map?.getLayerByName(id)){
      return true;
    }else if (map?.getLayerByName(juststem(id))){
      return true;
    }else {
      //check datasource
      const maplayers = map?.getMapLayers(maplayer => justfname(maplayer.datasource)===justfname(id))
      return len(maplayers)>0;
    }
}


export default function Jobs() {

  const [project, setProject] = useContext(QgisContext)
  const Q = new QgisProject(project, setProject)
  
  const [jobs, setJobs] = useState([])
  const [delay, setDelay] = useState(__DELAY__)
  const [cache, setCache] = useState({})
  const confirm = useConfirmDialog()

  const onJobExecutionDone = async(event) => {

    for (let filename of event.filenames) {
        let item = {filename:filename, scenario:event.scenario, command:event.command}
        let groupname = groupNameBy(item)
        let visible   = visibilityByFileName(item)
        let disabled  = isJobPredefined(filename)
        //visibility, zoomto, permanent        

        //S50-274 - Se il layer esiste non lo aggiorno
        // Untrim produce notevoli inconvenienti a causa dei suoi layer
        if (layerAreadyExists(project, filename)){
          return;
        }// end
          
        await addLayerToProject ({
           project:project, 
           filename:filename, 
           groupname:groupname, 
           visible:visible,
           expanded:false,
           disabled:disabled, 
           cmapname:wellKnownCmapName(item),
           permanent: filename === event.filenames[event.filenames.length-1] //save only @ last one
        })
      }
  };


  const onMouseOver = () => {
      setDelay(__DELAY__);
  };

  
  const getJobLabel = (item) => {

    if (isJobPredefined(item.name))
      return "pre-processing"
    else if (item.type.includes("preprocess"))
      return "pre-processing"
    else if (item.type ==="safer_rain_flooding")
      return "rainfall"
    else if (item.type ==="safer_river_flooding")
      return "river"
    else if (item.type ==="safer_coast_flooding")
      return "coastal"
    return item.type
  }


  const groupBy = (job_list) => {
    let job_dict = {};
    for (let item of job_list) {
        let category = getJobLabel(item);
        if (job_dict[category]) {         // add item to list or create list if not exists
          job_dict[category].push(item);
        } else {
          job_dict[category] = [item];
        } 
    }
    return job_dict;
  }

  
  useInterval(() => {
    //polling will slowdown of 20% every check
    let interval = Math.min(delay * 1.2, 16000);

    
    listjob().then((response) => {
      
      if (response && response.data) {
        setJobs(response.data);
        response.data.forEach((item) => {

          //console.log(item)

          if (item.status === "done" && cache[item.name] !== "done"){
                       
            // patch - this force reloading project
            setDelay(delay+Math.random())

            const workdir = justpath(project.filename);
            if (!workdir) return;
            
            item.workdir = workdir;
            const filewd  = `${workdir}/WD_${item.name}.tif`;
            let filedmg = `${workdir}/DMG_${item.name}.shp`;
            let filenames = [filewd]

            if (item.command.includes("--dmg")){
              filenames = [filedmg, ...filenames]
            }  
              
            // patch for RAINFALL jobs

            if (isJobPredefined(item.name)){
              filenames = [filewd]
            
            }else if (item.name.startsWith("PRE-DEM")) {
              filenames = []
            
            }else if (item.name.startsWith("PRE-RAIN")) {
              const filewsheds  = `${workdir}/watersheds.labels.shp`
              const filebspots  = `${workdir}/bluespots.labels.shp`
              const filestreams = `${workdir}/streams.shp`
              filenames = [filewsheds, filebspots, filestreams]
            
            }else if (item.name.startsWith("PRE-COAST")) {
              filenames = []
            
            }else if (item.name.startsWith("DMG_")){
                
              filedmg = `${workdir}/${item.name}.shp`;
              filenames = [filedmg]
                
            }else if (item.command.includes("--model untrim")){

              const filemax = `${workdir}/WD_${item.name}.max.tif`
              const fileflood = filemax.replace(".max.tif", ".mask.shp")
              filenames = [...getUntrimShots(item), filemax, fileflood]

              if (item.command.includes("--dmg")){
                filedmg = `${workdir}/DMG_${item.name}.max.shp`;
                filenames = [filedmg, ...filenames]
              }
              
            }else if (item.type ==="safer001"){
              
              const filesar = `${workdir}/SAR_${item.name}.tif`
              const fileopt = `${workdir}/OPT_${item.name}.tif`
              const filegfm = `${workdir}/GFM_${item.name}.tif`
              filenames = [filesar, fileopt, filegfm]

            }else if (item.type ==="cosmo"){
              
              const filecosmo = `${workdir}/COSMO_${item.name}.tif`  
              filenames = [filecosmo]

            }else if (item.type ==="safer002"){
              //Extract with regex the out filename
              // es: command = "extract --out out.tif --model untrim --tmax 36000 ..."
              filenames = []
              let regex = new RegExp(/--out (\S+)/, "i");
              let match = item.command.match(regex);
              
              if (match){
                let filedtm = `${match[1]}`.replace(__drive__,"")
                //rimuovere tutti i dem presenti
                
                //patch for safer002 - remove the initial fake dem
                Q.removeLayer("SAFER002_DEM_TEMP", false, false)
                
                filenames = [filedtm]
              }

            }else if (item.type ==="safer003"){
              
              for (let filename of listify(item.output)){
                if (filename.endsWith(".tif"))
                  filenames.push(`${workdir}/chrs_output/Safer003/${filename}`)
              }
            }
            
              if (len(filenames) && project.qgis && project.qgis["@version"]){
                cache[item.name] = item.status
                setCache({...cache})
                onJobExecutionDone({ filenames: filenames, scenario:item.type, command:item.command })
              }
            }

            //Se c'è qualche processo che sta andando riporto l'attenzione
            if (item.status !== "done") interval = __DELAY__;
         
          });
          setDelay(interval);
      }
    }); //end then
  }, delay);

  return (
    <Grid container direction={"row"} 
      //border={1} 
      //borderColor={"gray"} 
      //borderRadius={1} 
      onMouseOver={onMouseOver}>

      {/* TABLE HEADER */}
      <Grid item xs={12} >
        <Grid container direction="row" justifyContent={"space-between"} padding={1}>
          <Grid item xs={1}> <Typography component="div" sx={{ flexGrow: 1 }}> 
            {/* empty */}
          </Typography> </Grid>
          <Grid item xs={1}> <Typography component="div" sx={{ flexGrow: 1 }}>
            %
          </Typography> </Grid>
          <Grid item xs={3}> <Typography component="div" sx={{ flexGrow: 1 }}>
            Job
          </Typography> </Grid>
          <Grid item xs={2}> <Typography component="div" sx={{ flexGrow: 1 }}>
            Duration
          </Typography> </Grid>
          <Grid item xs={1}> <Typography component="div" sx={{ flexGrow: 1 }}>
            
            {/* KILL ALL JOBS */}
            <Tooltip title="Kill all jobs">
              <IconButton
                  size="small"
                  onClick={ ()=> {
                    confirm({
                      title: "Remove",
                      description: "Are you sure to remove and kill all the jobs?",
                      onConfirm:  () =>{ removejob(Q).then(()=>{setCache({})}) }//removejob,
                    });
                  }}
                  >
                      <Delete />
                </IconButton>
              </Tooltip>
          </Typography> </Grid>
        </Grid>
      </Grid>


      {/* TABLE BODY */}
      <Grid item xs={12} >
        <Grid container direction="column">
        {
          Object.entries(groupBy(jobs)).map((values, j) => {
            let [category, items] = values;
            return <CollapsibleRowGroup key={`group${j}`} category={category} items={items} cache={cache} setCache={setCache} />
          })
        }
        </Grid>
      </Grid>


    </Grid>

  );
}

const CollapsibleRowGroup = ({ category, items, cache, setCache }) => {
  
  const [open, setOpen] = useState(false);
  const [project, setProject] = useContext(QgisContext)
  const Q = new QgisProject(project, setProject)

  const confirm = useConfirmDialog()
  let jobs = items.sort((a, b) => (a.progress > b.progress) ? -1 : 1) // sort jobs by progress descending

  // check if all jobs progress -1
  let overallProgress = null
  if (jobs.every((item) => item.progress === -1)) {
    overallProgress = -1
  } else {
    let totalJobs = jobs.length
    let doneJobs = jobs.filter((item) => item.status === "done").length
    overallProgress = totalJobs > 1 ? Math.round(doneJobs / totalJobs * 100) :  jobs[0].progress
  }
  
  
  
  return (
      <>
      {/* ------------------------------------------ CATEGORY ROW ------------------------------------------ */}
      <Grid item xs={12}>
        <Grid container direction="row" 
          justifyContent={"space-between"} 
          alignContent={"center"} 
          padding={1} 
          borderTop={1} 
          borderColor= {"#dcdcdc"}>
          
          {/* EXPAND BUTTON */}
          <Grid item xs={1}> 
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}> 
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />} 
            </IconButton>  
          </Grid>

          {/* PROGRESS */}
          <Grid item xs={1}> <Typography component="div" sx={{ flexGrow: 1 }}>
            {<CircularProgressWithLabel value={overallProgress} size={18} />}
            
          </Typography> </Grid>

          {/* NAME */}
          <Grid item xs={5}> <Typography component="div" sx={{ flexGrow: 1 }}>
            {category}
          </Typography> </Grid>


          {/* DELETE BUTTON */}
          <Grid item xs={1}> <Typography component="div" sx={{ flexGrow: 1 }}>
            <Tooltip title={`Kill all ${category} jobs`}>            
              <IconButton
                size="small"
                onClick={() => {
                  confirm({
                    title: "Remove",
                    description: `Are you sure to stop and remove all the jobs of type ${category}?`,
                    // TODO qui fare per tutti i jobs di quel tipo
                    onConfirm: () => {
                      items.map((item) => (
                        removejob(Q, item.jid).then(() => {
                            cache[item.name] = undefined //remove the cache selectively
                            setCache({...cache})
                        })
                      ))
                    },
                  });
                }}
                >
                <Delete />
              </IconButton>
            </Tooltip>
          </Typography> </Grid>
        </Grid>
      </Grid>
      
      
        

      {/* ------------------------------ COLLAPSIBLE ROWS ------------------------------------ */}

        <Collapse in={open} timeout="auto">
          
          {/* HIDDEN ROW */}
          
              {
                jobs.map((item, j) => (
                  <Grid item key={`job${j}`} xs={12}>
                    <Grid container direction="row" justifyContent={"space-between"} padding={1}>

                      {/* JOB TYPE */}
                      <Grid item xs={1}> 
                        <p hidden>
                          {item.mode = item.command.includes("--mode local") ? "local" : item.mode}
                          {item.mode = item.command.includes("--mode lambda") ? "lambda" : item.mode}
                          {item.mode = item.command.includes("--mode batch") ? "batch" : item.mode}
                          {item.mode = item.command.includes("--mode ecs") ? "ecs" : item.mode}
                        </p>
                        <IconButton> <IconJob {...item} /> </IconButton>
                      </Grid>

                      {/* PROGRESS */}
                      <Grid item xs={1}> 
                        <Typography component="div" sx={{ flexGrow: 1 }}>
                         <CircularProgressWithLabel value={item.progress} size={18} />
                        </Typography> 
                      </Grid>

                      {/* NAME */}
                      <Grid item xs={3}> 
                      <Tooltip title={<Typography>{b64decode(item.description)}</Typography>}>
                        <Typography component="div" sx={{ flexGrow: 1 }}>
                          {item.name}
                        </Typography> 
                        </Tooltip>
                      </Grid>

                      {/* TIME */}
                      <Grid item xs={2}> <Typography component="div" sx={{ flexGrow: 1 }}>
                        { humantime(elapsedTime(item.starttime, item.endtime)) }
                      </Typography> </Grid>


                      {/* DELETE BUTTON */}
                      <Grid item xs={1}> 
                      <Tooltip title={`Kill job ${item.name}`} >
                        <IconButton
                          size="small"
                          onClick={() => {
                            confirm({
                              title: "Remove",
                              description: `Are you sure to stop and remove this job: ${item.name}?`,
                              // TODO qui fare per tutti i jobs di quel tipo
                              onConfirm: () => {
                                removejob(Q, item.jid).then(() => {
                                    cache[item.name] = undefined //remove the cache selectively
                                    setCache({...cache})
                                })
                              },
                            });
                          }}
                          >
                          <Delete />
                        </IconButton> 
                      </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              }
          
        </Collapse>
      </>
  )
}