import React, { useContext } from "react";
import Button from "@mui/material/Button";
import { QgisContext, QgisProject } from "@SaferPlaces2023/safer-map";
import { user_post} from "../utils/http";
import SaveIcon from '@mui/icons-material/Save';
import { useConfirmDialog } from "react-mui-confirm";
import Tooltip from '@mui/material/Tooltip';
import { getUserName } from "../utils/const";
import { saveProject } from "../utils/requests";


/**
 * @param {string} projectname name of the project we want to save. 
 * @param {string} text Text to visualize inside the button. By default is empty. Only the save icon is shown 
 * @param {boolean} disabled Disable the button and show a tooltip when the mouse goes over it
 * @param {string} textWhenDisabled Text shown on the tooltip when disabled is true, show a tooltip
 * @param {function} callback Function called as soon as the project is saved
 * @returns 
 */
const SaveProjectButton = ({
    disabled = false, 
    projectName = undefined, 
    textWhenDisabled = "Impossible to save project",
    callback = () => {}
}) => {

    const [project, setProject] = useContext(QgisContext) 
    const Q = new QgisProject(project, setProject)
    const confirmDialog = useConfirmDialog();

    const dialogTitle = "Project already existing"
    const dialogDescription = "A project with this name already exists. Do you want to overwrite it?"
    
    const saveRequest = (overwrite) => {
        
        Q.setUserName(getUserName())
        Q.setDefaultViewExtent()

        // Save project Button [RENAME] fromd DigitalTwin_XXXX to projectName
        return savePng(project) 
        .then(()=>saveProject({xml: Q.ExportToXml(), name: projectName, overwrite: overwrite}))
    }

    const savePng = async(project) => {
        const projectname = Q.getProjectName()
        await user_post("/api/savepng",{"data": project.map.ExportToPng(-1,200), "filename": `${projectname}/screenshot.png`})
    }


    async function handleSave () {

        const response = await saveRequest(false)
        if (response.status !== "success") {
            confirmDialog({
                title: dialogTitle,
                description: dialogDescription,
                onConfirm: async() => {
                    await saveRequest(true)
                    callback()
                }
            }) 
        } else {
            callback()
        }
    };

    return (
        <>
            <Tooltip title={disabled ? textWhenDisabled : ""} followCursor={true}>
            <span>
                <Button
                    startIcon={<SaveIcon />}
                    color="primary"
                    variant="outlined"
                    onClick={handleSave}
                    disabled={disabled}
                    >
                {`Save ${projectName}`}
                </Button>
            </span>
            </Tooltip>
            
        </>
  );
};

export default SaveProjectButton;
