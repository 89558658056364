import { ListItemIcon, ListItemText, MenuItem } from "@mui/material"
import React from "react"



export const MenuItemOpt = (props) => {
    return (props.hidden) ? null : (
        <MenuItem onClick={props.action} disabled={props.disabled}>
            <ListItemIcon>
                {props.icon} 
            </ListItemIcon>
            <ListItemText>{props.text}</ListItemText> 
        </MenuItem>
    )
}