import React, {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SellIcon from '@mui/icons-material/Sell';
import { useEvent, fireEvent } from '../utils/events';
import { user_post } from '../utils/http';
import { isTag } from '../utils/words';


export default function TagnameDialog() {

    const [text, setText] = useState("")
    const [error, setError] = useState("")
    const [projectname, setProjectname] = useState("")
    const [open, setOpen] = useState(false);


    /** receive the event for opening the dialog */
    const handleOpenDialog = (event) => {
        setText("")
        setProjectname(event.detail.projectname)
        setOpen(true) 
    }
    
    /** Close the dialog */
    const handleClose = () => {
        setOpen(false);
    }

    const handleConfirm = () => {
        //save the nre tag
        if (validateTag(text)) {
            user_post("/api/tags", { projectname: projectname, tagname: text.trim()}).then(()=>fireEvent("event:reload")).then(handleClose)
        }else{
            setError("Invalid tag name")
        }
    }

    /** update the textfield in the dialog */
    const handleTextChange = (event) => {
        
        setText(event.target.value)
        if (validateTag(event.target.value)) {
            setError("")
        }else{
            setError("Invalid tag name")
        }
    }

    const validateTag = (tag) => {

        return isTag(tag)

    }

    useEvent("project:newtag", handleOpenDialog)

    return (
    
        <Dialog open={open} onClose={handleClose} fullWidth sx={{maxWidth: '50%', margin:'10px auto'}}>
            <DialogTitle><SellIcon/>Add a new tag for {projectname}</DialogTitle>
            <DialogContent>
            
            <TextField
                autoFocus
                id="name"
                value={text}
                type="email"
                fullWidth
                variant="standard"
                onChange={handleTextChange}
                onKeyDown={(e) => {if (e.key === 'Enter') handleConfirm()}}
                error={error !== ""}
                helperText={error}
            />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">Cancel</Button>
                <Button onClick={handleConfirm} variant="contained">Confirm</Button> 
            </DialogActions>
        </Dialog>
    );
}